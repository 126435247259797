/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './Loading.css';
@import './dateTime.css';

*{
    font-family: 'Nunito Sans', sans-serif !important;
    padding: 0;
    margin: 0;
}
input{
    outline: none;
}
input[type=text], select, textarea {
    resize: vertical;
}
  
